@font-face {
  font-family: 'HarmonyOS Sans SC';
  src: url('../../assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'HarmonyOS Sans SC';
  src: url('../../assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'HarmonyOS Sans SC';
  src: url('../../assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'HarmonyOS Sans SC';
  src: url('../../assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'HarmonyOS Sans SC';
  src: url('../../assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Black.ttf') format('truetype');
  font-weight: 900;
}
