.header{
  position: fixed;
  padding-top: .15rem;
  padding-bottom: .1rem;
  z-index: 99;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 0px;
  width: 100%;
  transition: box-shadow 0.4s linear 0.3s;
  &.shadow{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;
  }
  .container{
    display: flex;
    // max-width: 1370px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: auto;
    // padding: 0px 102px;
    padding: 0 3.4rem;
    .logo{
      height: .5rem;
      cursor: pointer;
    }
    .nav{
      display: flex;
      .item{
        font-size: .24rem;
        font-weight: bold;
        margin-left: 56px;
        letter-spacing: 2px;
        color: rgb(0, 0, 0);
        transition: color 0.2s linear 0s;
        cursor: pointer;
        &.active,&:hover{
          color: #FE7B00;
        }
      }
    }
    .KebabMenuContainer{
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: 20px;
      width: 30px;
      height: 20px;
      transform: translateY(-50%);
      transition-property: height, width, right;
      transition-timing-function: ease-in-out;
      transition-duration: 0.4s;
      z-index: 999;
      .KebabLine {
        position: absolute;
        transition: all 0.2s ease-in-out 0s;
        background: rgb(0, 0, 0);
        opacity: 1;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        display: inline-block;
        &.Line1 {
          top: 0px;
        }
        &.Line2{
          top: 50%;
        }
        &.Line3{
          top: 100%;
        }
      }
      &:hover {
        height: 26px;
        width: 38px;
        right: 21px;
      }
      &.open{
        .KebabLine{
          &.Line1{
            transform: rotate(45deg);
            top: 50%;
          }
          &.Line2 {
            top: 50%;
            opacity: 0;
            left: 150%;
          }
          &.Line3 {
            transform: rotate(-45deg);
            top: 50%;
          }
        }
        
      }
    }
    .MobileMainMenuContainer {
      position: fixed;
      right: -100%;
      width: 100%;
      top: 0px;
      bottom: 0px;
      background: rgb(235, 238, 242);
      padding: 60px 0px 0px;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      align-items: center;
      transition: right 0.2s ease-out 0s;
      .logo{
        height: 32px;
        cursor: pointer;
      }
      .MenuMobileLinkContainer{
        padding: 90px 0px;
        text-align: center;
        .MenuMobileLink{
          font-size: 28px;
          text-transform: uppercase;
          letter-spacing: 2px;
          color: rgb(0, 0, 0);
          transition: color 0.2s linear 0s;
          padding: 20px 0px;
          &.active{
            color: rgb(255, 109, 115);
          }
        }
      }
      &.open{
        right: 0;
      }
      &.close{
        right: -100%;
      }
   }
    
  }
}
@media (min-width: 960px) and (max-width: 1510px){
  .header {
    .container{
      padding: 0 1.4rem;
    }
  }
}

@media screen and (max-width: 960px){
  .header{
    .container{
        padding: 0px .42rem;
        .logo{
          height: .35rem;
        }
    }
  }
}