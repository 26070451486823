.home {
  overflow: hidden;
  width: 100%;
  position: relative;
  .main{
    position: relative;
    width: 100%;
    // max-width: 1370px;
    padding: 0 3.4rem;
    min-height: 100vh;
    margin: auto;
    padding-top: .8rem;
    .container{
      position: relative;
      width: 100%;
      // padding: 0px 102px;
      .main-row{
        position: relative;
        width: 100%;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: 125px;
        flex-wrap: wrap;
        .left{
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding-right: 13px;
          padding-left: 0px;
          position: relative;
          flex-wrap: wrap;
          flex-direction: column;
        }
        .right{
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding-right: 0px;
          padding-left: .13rem;
          position: relative;
          flex-wrap: wrap;
          flex-direction: column;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        &.row-reverse{
          flex-flow: row-reverse wrap;
        }
        .title{
          font-size: .55rem;
          margin-bottom: .3rem;
          letter-spacing: -.005rem;
          line-height: 1.1;
          // margin-top: -0.12rem;
          font-weight: bold;
          text-transform: capitalize;
          u{
            color: #FE7B00;
            letter-spacing: -.005rem;
            text-decoration: none;
          }
        }
        .des{
          font-size: .2rem;
          letter-spacing: .001rem;
          line-height: 1.2;
          padding-bottom: .24rem;
          padding-left: 0px;
          max-width: 100%;
          font-weight: 500;
          margin: 0;
          text-align: left;
          font-family: HarmonyOS Sans SC;
        }
        .pic_box{
          display: inline-block;
          width: 100%;
          position: relative;
        }
      }
      .Heading{
        margin-top: -0.12rem;
        font-size: .36rem;
        font-weight: bold;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        margin-bottom: .33rem;
        line-height: 1.3;
        width: 100%;
        .GameContainerFreeBadge{
          margin: 0 0px 0px .12rem;
          color: rgb(255, 255, 255);
          background: #1AAAFF;
          padding: .06rem .24rem;
          font-size: .22rem;
          line-height: 1;
          height: .38rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.2em;
          border-radius: .26rem;
        }
      }
      .subtitle{
        font-size: .36rem;
        font-weight: normal;
        letter-spacing: 0em;
        color: #000000;
        margin-bottom: .06rem;
      }
      .ButtonContainer{
        font-size: .26rem;
        letter-spacing: 0.2px;
        line-height: 1.2;
        padding: .16rem .38rem;
        background: #FF5340;
        border-radius: .2rem;
        font-weight: bold;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border: none;
        outline: none;
        color: rgb(255, 255, 255);
        cursor: pointer;
        margin-top: .06rem;
        .ButtonContent{
          margin-right: .12rem
        }
        .icon_arrow{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          fill: currentcolor;
          width: 24px;
          height: 24px;
          transition: margin 0.4s cubic-bezier(0.68, -0.4, 0.4, 1.48) 0s;
        }
        &:hover svg {
            margin-left: 20px;
        }
        &.coming{
          background: #FFDA4A;
          color: #000000;
          font-weight: 500;
        }
      }
      .spec{
        display: inline-block;
        width: 100%;
        height: .8rem;
      }
      .section1{
        .left{
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-basis: 0px;
          padding: 0px 1rem 0px 0px;
        }
        .right{
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-basis: 0px;
          padding: 0;
          align-items: center;
        }
      }
      .section2{
        .left{
          width: 33.3333%;
        }
        .right{
          width: 66.6667%;
          padding-left: 13px;
          .BoxListContainer{
            width: 100%;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: auto;
            margin-top: .88rem;
            // max-width: 900px;
            .BoxListItem{
              // width: 230px;
              // height: 235px;
              width: calc(33.33% - 24px);
              min-width: 2.25rem;
              // height: 180px;
              padding: .48rem 0;
              border-radius: .3rem 0px .5rem .3rem;
              background: #1AAAFF;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              flex-direction: column;
              margin: 0px auto;
              // border: 10px solid rgb(255, 255, 255);
              .title{
                font-size: .38rem;
                color: rgb(255, 255, 255);
                letter-spacing: -0.8px;
                font-weight: bold;
                margin-bottom: .14rem;
                margin-top: 0;
              }
              .text{
                font-size: .22rem;
                font-weight: 500;
                text-align: center;
                text-transform: capitalize;
                letter-spacing: 0em;
                color: #000000;
                
              }
            }

            // &::before{
            //   content: '';
            //   position: absolute;
            //   top: 0;
            //   left: -33.33%;
            //   transform: translateX(12px);
            //   width: calc(33.33% - 24px);
            //   height: 1.8rem;
            //   border-radius: .3rem 0px .5rem .3rem;
            //   opacity: 1;

            //   background: rgba(26, 170, 255, 0.05);

            //   box-sizing: border-box;
            //   border: 1px solid rgba(26, 170, 255, 0.3);
            // }
            // &::after{
            //   content: '';
            //   position: absolute;
            //   top: 0;
            //   right: -16.67%;
            //   width: calc(16.67% - 24px);
            //   transform: translateX(-12px);
            //   height: .9rem;
            //   border-radius: .15rem 0px .25rem .15rem;
            //   opacity: 1;

            //   background: rgba(26, 170, 255, 0.05);

            //   box-sizing: border-box;
            //   border: 0.5px solid rgba(26, 170, 255, 0.3);
            // }
          }
        }
      }
      .section3{
        margin-top: .8rem;
        .left{
          width: 55%;
        }
      }
      .section4{
        width: 100%;
        // max-width: 916px;
        padding: 0 1rem;
        margin: auto auto 0;
        position: relative;
        .left{
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-basis: 0px;
        }
        .right{
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-basis: 0px;
          padding: 0px 0 0px 1.2rem;
          min-width: 390px;
        }
      }
      .section5{
        width: 100%;
        // max-width: 916px;
        padding: 0 1rem;
        margin: auto auto .5rem;
        position: relative;
        // .main-row {
        //   justify-content: center;
        //   &.row-reverse{
        //     flex-flow: row-reverse nowrap;
        //   }
        // }
        .left{
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-basis: 0px;
         
        }
        .right{
          
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-basis: 0px;
          padding: 0px .6rem 0px 0px;
          min-width: 330px;
          box-sizing: border-box;
         
        }
      }
      .section6{
        width: 100%;
        // max-width: 916px;
        padding: 0 1rem;
        margin: auto auto;
        position: relative;
        .left{
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-basis: 0px;
          padding: 0px 0px 0px 1.3rem;
          min-width: 390px;
        }
        .right{
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-basis: 0px;
          padding: 0;
          
        }
      }
    }
  }
  .footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .logo{
      height: .5rem;
      img{
        height: 100%;
      }
    }
    .company{
      font-size: .24rem;
      margin-top: .2rem;
    }
    .nav{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .3rem 0 .2rem;
      .item{
        padding: 0 .68rem;
        a{
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0em;
          text-decoration: underline;

          color: #000000;
        }
      }
    }
  }
  .bg1{
    width: 5.5rem;
    height: 5.35rem;
    background: url(../../assets/img/bg1.png) no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top: 15%;
    left: -1.7rem;
    pointer-events: none!important;
  }
  .bg2{
    width: 5.5rem;
    height: 5.35rem;
    background: url(../../assets/img/bg2.png) no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    top: 32%;
    right: -2rem;
    pointer-events: none!important;
  }
  .bg3{
    width: 9.89rem;
    height: 11.66rem;
    background: url(../../assets/img/bg3.png) no-repeat center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    pointer-events: none!important;
    left: -2.56rem;
  }
}

@media (min-width: 960px) and (max-width: 1510px){
  .home {
    .main{
      padding: 0 1.4rem;
    }
  }
}
@media screen and (max-width: 960px){
  .home {
    .main{
      position: relative;
      width: 100%;
      max-width: 1370px;
      min-height: 100vh;
      margin: auto;
      padding: 0;
      padding-top: 1rem;
      .container{
        padding: 0px 42px;
        .main-row{
          .left,.right{
            min-width: 100%;
            padding: 0;
          }
          .title{
            font-size: .36rem
          }
          .des{
            font-size: .17rem;
          }
        }
        .ButtonContainer{
          width: 100%;
          padding: 9px 12px;
          line-height: 1.5;
          font-size: .18rem;
          margin-top: .1rem;
          .ButtonContent{
            margin-right: 0
          }
          svg {
            display: none;
          }
          &.coming{
            background: #FFDA4A;
            color: #000000;
            font-weight: 500;
          }
        }
        .section2{
          .right{
            .BoxListContainer{
              position: relative;
              align-items: stretch;
              margin-top: .3rem;
              .BoxListItem{
                margin-bottom: .2rem;
                min-width: auto;
                padding: 0.2rem 0;
                border-radius: 0.12rem 0px 0.18rem 0.12rem;
                width: calc(33.33% - 12px);
                .title{
                  font-size: .14rem;
                }
                .text{
                  font-size: .12rem;
                }
              }
              &::before,&::after{
                display: none;
              }
            }
          }
        }
        .section3{
          margin-top: .4rem;
        }
        .section4,.section5,.section6{
          padding: 0;
        }
        // .section5{
        //   .main-row.row-reverse{
        //     flex-flow: row-reverse wrap;
        //   }
        // }
        .spec{
          display: inline-block;
          width: 100%;
          height: 10px;
        }
      }
    }
    .footer{
      .logo{
        height: .35rem;
      }
      .nav{
        padding: .2rem 0 .25rem;
        .item{
          padding: 0 .2rem;
          a{
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0em;
            text-decoration: underline;
  
            color: #000000;
          }
        }
      }
    }

    .bg3{
      width: 4.95rem;
      height: 5.83rem;
      background: url(../../assets/img/bg3.png) no-repeat center;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      pointer-events: none;
      left: -.8rem;
    }
  }
  .mobile_hide{
    display: none!important;
  }


  
}
