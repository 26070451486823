.VideoPlayer{
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  &.no-cursor{
    cursor: none;
    .VideoPlayerContainer{
      cursor: none;
    }
  }
  .VideoPlayerContainer{
    width: 5.2rem;
    height: 5.2rem;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto; 
    position: relative;
    .PlayButton{
      position: absolute;
      transform: translate(-50%, -50%) scale(1, 1);
      width: 98px;
      height: 98px;
      display: inline-block;
      background: url(../../assets/img/play_icon.svg) 34px center / 42px 42px no-repeat #000000;
      box-shadow: 0px 0px 20px 0px rgba(141, 141, 141, 0.8);
      border-radius: 50%;
    }
  }
}



.video_model{
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: fixed;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition: transform 1s ease-in-out 0s;
  .bg{
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
  }
  .model_container{
    display: inline-block;
    position: relative;
    .VideoWindowCloseButton{
      position: absolute;
      top: -30px;
      right: -60px;
      width: 60px;
      height: 60px;
      z-index: 99;
      cursor: pointer;
      transform: rotate(0deg);
      transition: transform 1s ease-in-out 0s;
      .IconContainer{
        width: 50px;
        height: 50px;
        display: inline-block;
        color: rgb(255, 255, 255);   
        svg {
          transform: translate(5px, 5px);
        }
        svg path {
          stroke: rgb(255, 255, 255);
          transition: transform 1s ease-in-out 0s;
        } 
      }
      &:hover {
        transform: rotate(360deg);
        svg path {
          stroke: rgb(0, 70, 255);
        }
      }
    }
    .video_con{
      height: 80vh;
      width: auto;
      // max-width: 320px;
      margin: auto;
      video{
        height: 100%;
      }
    }
  }
  &.open{
    opacity: 1;
    z-index: 99;
  }
  &.close{
    opacity: 0;
    z-index: -99;
  }
}


@media screen and (max-width: 960px){
  .VideoPlayer{
    .VideoPlayerContainer{
      margin: 60px auto;
      width: 80vw;
      height: 80vw;  
    }
  }
  .video_model{
    .model_container{
      .VideoWindowCloseButton{
        top: 0px;
        right: -10px;
        background: rgb(0, 70, 255);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        .IconContainer{
          width: 40px;
          height: 40px;
        }
      }
      
    }
  }
}
